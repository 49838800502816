import { configureStore } from "@reduxjs/toolkit";
import assetSlice from "./asset-slice";
import portfolioSlice from "./portfolio-slice";

export default configureStore({
   reducer: {
      assets: assetSlice,
      portfolios: portfolioSlice,
   },
});
