import { ComponentStyleConfig } from "@chakra-ui/react";

const Tabs: ComponentStyleConfig = {
   parts: ["tabs", "tablist", "tab", "tabpanel"],

   baseStyle: {},

   variants: {
      enclosed: {
         tab: {
            border: "1px solid",
            mb: "-1px",
            _selected: {
               color: "beige.300",
               borderColor: "beige.200",
               borderBottomColor: "blue.700",
               _focus: {
                  boxShadow: "none",
               },
            },
         },
         tablist: {
            mb: "-1px",
            borderBottom: "1px solid",
            borderColor: "beige.200",
         },
      },
      line: {
         tab: {
            borderBottom: "2px solid",
            mb: "-1px",
            _selected: {
               color: "beige.300",
               borderBottomColor: "beige.200",
               fontWeight: "bold",
               _focus: {
                  boxShadow: "none",
               },
            },
         },
         tablist: {
            mb: "-1px",
            borderBottom: "1px solid",
            borderColor: "blue.700",
         },
      },
   },
};

export { Tabs };
