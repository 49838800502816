import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Asset = {
   client: number;
   asset: string;
   quantity: number;
   amount: number;
   type: string;
   isSelected: boolean;
   sellAmount: number;
   position_date: Date;
};

interface Portfolio {
   client: string;
   message?: string;
   portfolio: Asset[];
}

interface StateProps {
   assets: Portfolio;
}

const assetSlice = createSlice({
   name: "assets",
   initialState: {
      client: "",
      portfolio: [
         {
            asset: "",
            quantity: 0,
            amount: 0,
            type: "",
            isSelected: false,
            sellAmount: 0,
         },
      ],
   },
   reducers: {
      addAsset(state, { payload }: PayloadAction<Portfolio>) {
         return payload;
      },
      selectAsset(state, { payload }: PayloadAction<string>) {
         const selectAsset = state.portfolio.map(asset =>
            asset.asset === payload
               ? { ...asset, isSelected: !asset.isSelected }
               : asset,
         );

         return {
            client: state.client,
            portfolio: selectAsset,
         };
      },
      selectAllAssets(state, { payload }: PayloadAction<boolean>) {
         const selectAll = state.portfolio.map(asset => ({
            ...asset,
            isSelected: payload,
         }));

         return {
            client: state.client,
            portfolio: selectAll,
         };
      },
      addTotalAmountToSell(
         state,
         { payload }: PayloadAction<{ asset: string; amount: string }>,
      ) {
         const assetsAmount = state.portfolio.map(data => {
            if (data.asset === payload.asset) {
               const sellAmount = payload.amount
                  .replace(/\D/g, "")
                  .replace(/(\d)(\d{2})$/, "$1,$2")
                  .replace(",", ".");

               return {
                  ...data,
                  sellAmount: Number(sellAmount),
               };
            }

            return data;
         });

         return {
            client: state.client,
            portfolio: assetsAmount,
         };
      },
      removeSelectedAsset(state, { payload }: PayloadAction<string>) {
         const portfolio = state.portfolio.filter(
            asset => asset.asset !== payload,
         );

         return {
            client: state.client,
            portfolio,
         };
      },
   },
});

export const {
   addAsset,
   selectAsset,
   addTotalAmountToSell,
   selectAllAssets,
   removeSelectedAsset,
} = assetSlice.actions;

export const useAssets = (state: StateProps) => {
   return state.assets;
};

export const useSelectedAssets = (state: StateProps) => {
   const selectedAssets = state.assets.portfolio.filter(
      asset => asset.isSelected === true,
   );

   return {
      client: state.assets.client,
      assets: selectedAssets,
   };
};

export default assetSlice.reducer;
