import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AutomatedPortfolioType = {
   id: string;
   name: string;
   min_application: number;
   isSelected?: boolean;
};

interface StateProps {
   portfolios: AutomatedPortfolioType[];
}

const portfoliosInitialState: AutomatedPortfolioType[] = [
   {
      id: "",
      name: "",
      min_application: 0,
      isSelected: false,
   },
];

const portfolioSlice = createSlice({
   name: "portfolios",
   initialState: portfoliosInitialState,
   reducers: {
      addPortfolios(
         state,
         { payload }: PayloadAction<AutomatedPortfolioType[]>,
      ) {
         return payload;
      },
      selectPortfolio(state, { payload }: PayloadAction<string>) {
         const selectPortfolio = state.map(portfolio =>
            portfolio.id === payload
               ? { ...portfolio, isSelected: !portfolio.isSelected }
               : { ...portfolio, isSelected: false },
         );

         return selectPortfolio;
      },
   },
});

export const { addPortfolios, selectPortfolio } = portfolioSlice.actions;

export const usePortfolios = (state: StateProps) => {
   return state.portfolios;
};

export const useSelectedPortfolio = (state: StateProps) => {
   const selectedPortfolio = state.portfolios.find(
      portfolio => portfolio.isSelected === true,
   );

   return selectedPortfolio;
};

export default portfolioSlice.reducer;
